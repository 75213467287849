import React from "react"
import { CssGrid } from "../components/CssGrid"
import classNames from "classnames"

import Layout from "../components/layout"
import { Hero } from "../components/Hero"
import Seo from "../components/Seo.js"

const NotFoundPage = () => (
  <Layout>
    <CssGrid>
      <Seo title="404: Not found" />
      <article>
        <div className="sticky error404">
          <h1>NOT FOUND</h1>
          <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
        </div>
      </article>
    </CssGrid>
  </Layout>
)

export default NotFoundPage
